import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { SectionFieldContainerProps, SectionFieldOption, TemplateScoreContextType } from 'typings';
import FieldTitle from './FieldTitle';
import useToggleStyles from '../../styles/Toggle';
import { TemplateScoreContext } from '../../contexts/TemplateScoreContext';
import { convertARGBNumberToRGBHexString, getFontColor } from '../../utils/common';
import { globalColors } from '../../styles/globalColorVariables';

const Toggle: React.FC<SectionFieldContainerProps> = (toggleProps) => {
  const templateScoreContext = React.useContext<TemplateScoreContextType>(TemplateScoreContext);
  const classes = useToggleStyles();
  const { sectionFieldDetails, sectionRank } = toggleProps;
  const { name, isMandatory, selectOptions, rank, extra } = sectionFieldDetails;
  const usedForScore = extra?.usedForScore ?? false;
  const [selected, setSelected] = React.useState(Array(sectionFieldDetails.selectOptions?.length).fill(false));
  const [selectedBGColor, setSelectedBGColor] = React.useState<string>(globalColors.primaryColor);
  const updateToggle = (value: boolean, index: number, score: number, color: number) => () => {
    templateScoreContext.updateTemplateScoreMap(sectionRank, rank, score, usedForScore);
    const selectedCopy = [];
    selectedCopy[index] = value;
    setSelected(selectedCopy);
    setSelectedBGColor(convertARGBNumberToRGBHexString(color));
  };
  const selectedFontColor = getFontColor(selectedBGColor);
  return (
    <div>
      <FieldTitle name={name} isMandatory={isMandatory} />
      <div className={classes.toggleContainer}>
        {selectOptions?.map((eachOption: SectionFieldOption, i: number) => (
          <ToggleButton
            classes={{ root: classes.toggleButton }}
            key={eachOption.rank}
            value={eachOption.name}
            selected={selected[i]}
            onChange={updateToggle(!selected[i], i, eachOption.score ?? 0, eachOption.color)}
            style={{
              backgroundColor: selected[i] ? selectedBGColor : globalColors.whiteColor,
              color: selected[i] ? selectedFontColor : 'rgb(0 0 0 / 38%)'
            }}
          >
            {eachOption.name}
          </ToggleButton>
        ))}
      </div>
    </div>
  );
};

export default Toggle;
